<template>
  <div>
    <div style="
        text-align: center;
        background: #1e7dfc;
        color: #fff;
        font-size: 16px;
        padding: 10px 0;
      ">
      报名系统
    </div>

    <!-- 苹果手机人脸拍照组件 -->
    <div class="iphonefaceCamera"
         id="iphonefaceCamera"
         v-show="face_back_device_type_iphone">
      <div style="
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 100px;
        ">
        <el-button type="primary"
                   value=""
                   class="faceMatch"
                   id="iphonefaceCamera1"
                   :disabled="text !== '拍 照 检 测'"
                   @click="learn_faceMatch_backstagePhoto_FACEMETCH_takePhote_iphoe()">{{ text }}</el-button>
      </div>

      <input type="file"
             @change="
          learn_faceMatch_backstagePhoto_FACEMETCH_faceCameraChange_iphoe
        "
             id="iphoneImage1"
             accept="image/*"
             capture="user"
             style="opacity: 0"
             ref="iphoneClick" />
    </div>

    <el-dialog title="请输入身份证号码"
               :visible.sync="dialogVisible"
               width="90%"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               :show-close="false">
      <span>
        <el-form :model="ruleForm"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="100px"
                 class="demo-ruleForm">
          <el-form-item label="姓名"
                        prop="realName">
            <el-input v-model="ruleForm.realName"
                      placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号码"
                        prop="cardNo">
            <el-input v-model="ruleForm.cardNo"
                      placeholder="请输入身份证"></el-input>
          </el-form-item>
          <el-form-item label="手机号"
                        prop="bindMobilde">
            <el-input v-model="ruleForm.bindMobilde"
                      placeholder="请输入手机号"></el-input>
          </el-form-item>

        </el-form>
      </span>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="submit('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <div class="publish"
         style="margin-top: 50px"
         v-show="camera">
      <video ref="video"
             id="video"
             v-show="cameraVideo"
             autoplay
             controls></video>
      <canvas style="display: none"
              id="canvasCamera"
              ref="canvas"></canvas>
      <div v-if="imgSrc"
           class="img_bg_camera">
        <img :src="imgSrc"
             class="tx_img" />
      </div>
      <div style="display: flex; justify-content: center; margin-top: 50px">
        <el-button :disabled="text !== '拍 照 检 测'"
                   type="primary"
                   @click="setImage">{{ text }}</el-button>
      </div>
    </div>
    <div v-if="isCema"
         style="display: flex;justify-content: center;align-items: center;">
      <img @click="filesHandl"
           style="width: 147.5px;"
           src="../assets/sz.png"
           alt="安徽中培测评职业技能鉴定有限公司服务平台" />
      <input type="file"
             v-show="false"
             @change="onFileListChange"
             id="img_control1"
             multiple
             accept="image/*" />
    </div>
    <div>
      <div style="font-size: 16px;color: #1e7dfc;">温馨提示：</div>
      <div style="font-size: 16px;color: #1e7dfc;">1：请按照模板图片进行自己本人的照片上传！</div>
      <div style="font-size: 16px;color: #1e7dfc;">2：请勿上传距离过远，无法识别人脸等照片！</div>
      <div style="font-size: 16px;color: #1e7dfc;">3：请勿上传多个相同照片！</div>
      <div>模板照片如下：</div>
      <div style="display:flex;justify-content: center">
        <img style="width: 24%;"
             :src="firstImage"
             alt="">
      </div>
      <div style="font-size: 22px;margin-bottom: 5px;">你的照片：</div>
      <div style="display: flex;flex-wrap: wrap;">
        <img v-for="(item,idx) in photoList"
             :key="idx"
             style="width: 23%;margin: 5px 5px 0 0"
             :src="item"
             alt="">
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { savePhoto } from "@/api/video/index";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      dialogVisible: true,
      ruleForm: {
        cardNo: "",
        realName: "",
        bindMobilde: "",
      },
      rules: {
        cardNo: [
          { required: true, message: "请输入身份证", trigger: "blur" },
          { min: 18, max: 18, message: "身份证长度为18位", trigger: "blur" },
        ],
        realName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 2, max: 8, message: "姓名长度为2-8位", trigger: "blur" },
        ],
        bindMobilde: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min:11, max:11, message: "手机号长度为11位", trigger: "blur" },
        ],
      },
      mediaStreamTrack: {},
      video_stream: "", // 视频stream
      imgSrc: "", // 拍照图片
      canvas: null,
      context: null,
      camera: false,
      cameraVideo: true,
      userId: "",
      face_back_device_type_iphone: false, //苹果设备
      photoNum: 0,
      text: "拍 照 检 测",
      photova: false,
      filesNum:0,
      fileList:[],
      isCema:false,//上传组件
      photoList:[],//用户拍的照片
      firstImage:'',
      pId:'',
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogVisible = false;
              // 打开摄像头
              if (this.camera) {
                console.log("打开摄像头");
                this.getCamera();
                this.cameraVideo = true
                this.camera = true;
              }
        } else {
          return false;
        }
      });
    },
    //图片点击
    filesHandl() {
      document.getElementById('img_control1').click()
    },
    //图片点击
    filesHandl1() {
      document.getElementById('img_control').click()
    },
    //上传五张照片
    onFileListChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (files.length !== 5) {
        this.$message('必须上传五张照片！')
        return false
      }
      for (let index = 0; index < files.length; index++) {
        console.log(files[index]);
        if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(files[index].name)) {
          this.photova = false;
          this.openAlert("请选择正确的图片格式上传！");
          return;
        }
        if (files[index].size > 10 * 1024 * 1024) {
          this.photova = false;
          this.openAlert("照片大小必须小于10M");
          return;
        }
        //小于100k的不压缩
        if (files[index].size < 0.1 * 1024 * 1024) {
          var reader = new FileReader();
          this.photova = true;
          reader.onload = (e) => {
            this.savaList('','',files[index].name,e.target.result);
          };
          reader.readAsDataURL(files[index]);
        } else {
          this.createImage(files[index], 1);
        }
        

      }
    },
    savaList(e, f,x,d) {
      this.filesNum = this.filesNum + 1
      this.ruleForm.image = d
      this.ruleForm.imageName = this.filesNum+x
      this.ruleForm.pId = this.pId
      savePhoto(this.ruleForm).then((res) => {
        if (res.data.data.result == "01") {
          this.photoNum = this.photoNum + 1;
          this.$message({
            message:
              this.photoNum + " 张照片上传成功！继续拍请更换环境，姿势，衣服等",
            type: "success",
          });
          this.photoList = res.data.data.imageUrl
          this.cameraVideo = true;
          this.imgSrc = "";
          this.text = "拍 照 检 测";
        } else {
          this.$message("照片上传失败，请重新拍照!");
          this.cameraVideo = true;
          this.imgSrc = "";
          this.text = "拍 照 检 测";
        }
      });
    },
    createImage(file, id) {
      let reader = new FileReader();
      let _this = this;
      _this.photova = true;
      _this.imageName = file.name;
      reader.readAsDataURL(file); //读取文件并将文件以URL的形式保存在resulr属性中 base64格式
      reader.onload = (e) => {
        // 文件读取完成时触发
        let result = e.target.result; // base64格式图片地址
        var image = new Image();
        image.src = result; // 设置image的地址为base64的地址
        image.onload = () => {
          // 图片加载完成后才能进行压缩处理，从而转换为base64 进行赋值
          let width = image.width; // 图片宽度
          let height = image.height;
          //压缩比例 0.2
          let dataUrl = _this.compress(image, width, height, 0.2);
          _this.image = dataUrl;
          if (id == 1) {
            _this.savaList('','',_this.imageName,dataUrl );
          } else {
            _this.savePhoto();
          }
        };
      };
      return;
    },
    savePhoto() {
      this.ruleForm.image = this.image
      this.ruleForm.imageName = this.imageName
      this.ruleForm.pId = this.pId
      savePhoto(this.ruleForm).then((res) => {
        if (res.data.data.result == "01") {
          this.photoNum = this.photoNum + 1;
          this.$message({
            message:
              this.photoNum + " 张照片上传成功！继续拍请更换环境，姿势，衣服等",
            type: "success",
          });
          this.photoList = res.data.data.imageUrl
          this.pId = res.data.data.pId
          this.firstImage = res.data.data.firstImage
          this.cameraVideo = true;
          this.imgSrc = "";
          this.text = "拍 照 检 测";

        } else {
          this.$message("照片上传失败，请重新拍照!");
          this.cameraVideo = true;
          this.imgSrc = "";
          this.text = "拍 照 检 测";
        }
      });
    },
    // 调用打开摄像头功能
    getCamera() {
      // 获取 canvas 画布
      this.canvas = document.getElementById("canvasCamera");
      this.context = this.canvas.getContext("2d");
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      console.log(navigator.mediaDevices);
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 正常支持版本
      navigator.mediaDevices
        .getUserMedia({
          video: true, //默认前置
          //video: { facingMode: { exact: "environment" } },//后置
        })
        .then((stream) => {
          // 摄像头开启成功
          this.mediaStreamTrack =
            typeof stream.stop === "function" ? stream : stream.getTracks()[0];
          this.video_stream = stream;
          this.$refs.video.srcObject = stream;
          this.$refs.video.play();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    faceMatch_backstagePhoto_FACEMETCH_compareAndSave_iphone(base64Photo) {
      this.ruleForm.image = base64Photo
      this.text = "上传中...";
      this.ruleForm.pId = this.pId
      savePhoto(this.ruleForm).then((res) => {
        if (res.data.data.result == "01") {
          this.photoNum = this.photoNum + 1;
          this.$message({
            message:
              this.photoNum + " 张照片上传成功！继续拍请更换环境，姿势，衣服等",
            type: "success",
          });
          this.photoList = res.data.data.imageUrl
          this.pId = res.data.data.pId
          this.firstImage = res.data.data.firstImage
          this.text = "拍 照 检 测";
          //关闭拍照组件打开上传组件
          this.isCema = true
          this.face_back_device_type_iphone = false
        }else {
          this.$message('上传失败！请重新上传')
        } 
      });
    },
    // 拍照 绘制图片
    setImage() {
      this.text = "上传中...";
      let videow = document.getElementById("video").clientWidth;
      let videoh = document.getElementById("video").clientHeight;
      this.canvas.setAttribute("width", videow);
      this.canvas.setAttribute("height", videoh);
      // 点击canvas画图
      this.context.drawImage(this.$refs.video, 0, 0, videow, videoh);
      // 获取图片base64链接
      const image = this.canvas.toDataURL("image/png", 0.3);
      this.imgSrc = image;
      this.cameraVideo = false;
      this.ruleForm.image = this.imgSrc
      this.ruleForm.pId = this.pId
      
      savePhoto(this.ruleForm).then((res) => {
        if (res.data.data.result == "01") {
          this.photoNum = this.photoNum + 1;
          this.$message({
            message:
              this.photoNum + " 张照片上传成功！继续拍请更换环境，姿势，衣服等",
            type: "success",
          });
          this.photoList = res.data.data.imageUrl
          this.pId = res.data.data.pId
          this.firstImage = res.data.data.firstImage
          this.cameraVideo = true;
          this.imgSrc = "";
          this.text = "拍 照 检 测";
          //关闭拍照组件打开上传组件
          this.isCema = true
          this.camera = false
          
          this.face_back_device_type_iphone = false
        } else {
          this.$message("照片上传失败，请重新拍照!");
          this.cameraVideo = true;
          this.imgSrc = "";
          this.text = "拍 照 检 测";
        }
      });
    },
    //苹果手机相机组件变化 ，拍照
    learn_faceMatch_backstagePhoto_FACEMETCH_faceCameraChange_iphoe(e) {
      var files = e.target.files || e.dataTransfer.files;
      let file = files[0];
      console.log(file.size);
      if (!files.length) return;
      //小于100kb的图片不压缩
      if (file.size < 0.1 * 1024 * 1024) {
        this.learn_faceMatch_backstagePhoto_FACEMETCH_CreateImage_iphone(file);
        return false;
      }
      //大于100kb的压缩
      let reader = new FileReader();
      reader.readAsDataURL(file); //读取文件并将文件以URL的形式保存在resulr属性中 base64格式
      reader.onload = (e) => {
        // 文件读取完成时触发
        let result = e.target.result; // base64格式图片地址
        var image = new Image();
        image.src = result; // 设置image的地址为base64的地址
        image.onload = () => {
          // 图片加载完成后才能进行压缩处理，从而转换为base64 进行赋值
          let width = image.width; // 图片宽度
          let height = image.height;
          let dataUrl = this.compress(image, width, height, 0.5);
          this.faceMatch_backstagePhoto_FACEMETCH_compareAndSave_iphone(
            dataUrl
          );
          // var strLength = dataUrl.length;
          // var fileLength = parseInt(strLength - (strLength / 8) * 2);
          // // 由字节转换为KB
          // var size = "";
          // size = (fileLength / 1024).toFixed(2);
          // console.log(size);
        };
      };
      // this.learn_faceMatch_backstagePhoto_FACEMETCH_CreateImage_iphone(file);
    },
    /*
     * 图片压缩
     * img   原始图片
     * width  压缩后的宽度
     * height  压缩后的高度
     * ratio  压缩比率
     */
    compress(img, width, height, ratio) {
      let canvas, ctx, img64;
      canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      img64 = canvas.toDataURL("image/jpeg", ratio);
      return img64;
    },
    //苹果手机拍照检测
    learn_faceMatch_backstagePhoto_FACEMETCH_takePhote_iphoe() {
      // document.getElementById("iphoneImage1").click();
      if (this.iphoneBtn) {
        return false;
      }
      this.$refs.iphoneClick.click();
    },
    //苹果手机获取bs64照片
    learn_faceMatch_backstagePhoto_FACEMETCH_CreateImage_iphone(file) {
     
      var reader = new FileReader();

      var vm = this;
      this.imageName = file.name;
      reader.onload = (e) => {
        vm.image = e.target.result;
        this.image = e.target.result;
        if (file) {
          this.faceMatch_backstagePhoto_FACEMETCH_compareAndSave_iphone(
            this.image
          );
        }
      };
      reader.readAsDataURL(file);
      return;
    },
    //获取设备类型
    faceMatch_backstagePhotoDevice_type() {
      let device_type = navigator.userAgent.toLowerCase();
      this.camera =
        device_type.indexOf("android") > -1 || device_type.indexOf("adr") > -1; //android终端
      this.face_back_device_type_iphone =
        device_type.indexOf("iphone") != -1 ||
        device_type.indexOf("ipad") != -1; //ios终端
      if (this.camera) {
        this.getCamera();
        this.cameraVideo = false
      }
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // 进入页面 自动调用摄像头
    this.faceMatch_backstagePhotoDevice_type();
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
};
</script>
<style  scoped>
/* //@import url(); 引入公共css类 */
video {
  width: 100%;
  height: 300px;
}
canvas {
  width: 100%;
  height: 300px;
}

.img_bg_camera  img {
    width: 100%;
    height: 300px;
  }
.faceMatch {
  margin: 0 auto;
}
</style>
