// request.js文件
import axios from 'axios';
// import { Notification, MessageBox, Message, Loading } from 'element-ui'
// import store from '@/store'
// 引入基础参数文件  和  baseURL配置文件
// import { BASE_URL } from './config'   // baseURL写在config.js文件中
const request = axios.create({
  baseURL: window.glob.baseURL,
  timeout: 10000
});
// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
   
    return response;
  },
  function (error) {
   
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default request;
