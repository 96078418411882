import request from "@/utils/request";

// 拍照
export function checkUser(data) {
  return request({
    url: "/resource/proj/checkUser.html",
    method: "post",
    data,
  });
}

//提交照片
export function savePhoto(data) {
  return request({
    url: "/resource/proj/savePhoto.html",
    method: "post",
    data,
  });
}
